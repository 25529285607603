.team {
	.members {
		// display: grid;
		// grid-template-columns: repeat(2, 50%);
		// grid-gap: var(--gap-2);
	}
	section.group {
		border-top: solid 3px var(--color-fg);
		&:last-of-type {
			border-bottom: solid 3px var(--color-fg);
		}
		padding: var(--gap-3) 0;

		h1, h2, h3 {
			@include highlight;
			color: var(--color-fg);
		}
	}
	.member {
		p {
			@include highlight;

		}
		.bio {

		}
		margin-top: var(--gap-2);
		display: grid;
		grid-template-columns: var(--logo-size) 1fr;
		grid-gap: var(--gap-2);
		div.emoji {
			width: var(--logo-size);
			font-size: 300%;
			height: var(--logo-size);
			background-color: rgba(black, .2);
			margin-top: -5px;
			color: var(--color-fg); 
			padding-top: 10px; 
			text-align: center;   
			@include radius(50%); 
		}
		span.emoji {
			font-size: 100%;
			width: 40px;
			display: inline-block;
			font-size: bold;
			&:after {
				content: ":";
				margin-left: 5px;
			}
		}

		.links {
			@include highlight;
			a:not(:last-of-type) {
				margin-right: 3px;
				display: inline-block;
			}

			
		}
	}
}