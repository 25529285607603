html.slide {
  h1, h2, h3, h4 {
    @include highlight;
  }
  body {
    background-color: var(--color-bg);
    background-image: url("../images/dot-bg.svg");
    padding: 0;
    margin: 0;
  }
  .sig {
    position: absolute;
    bottom: 0;
    left: var(--gap);

  }
  section {
    text-align: left;
    @include fadeIn;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--gap-2);
    }
    h1, h2 tt, h3 tt {
      text-transform: uppercase;
    }
  } 

  tt {
    @include highlight(yellow);
    font-family: var(--font);

    & + tt {
      margin-left: 10px;
    }
  }
  .reveal {
    font-size: 2rem;
    line-height: 130%;
    word-wrap: break-word;
    color: var(--color-fg);

    h1, h2, h3, h4, h5, h6 {
      color: var(--color-fg);
      line-height: 110%;

    }
    h1 {
      font-size: 3.77em;
    }
    h2 {
      font-size: 2.11em;
    }
    h3 {
      font-size: 1.55em;
    }
    h4, p, li, tr, th {
      font-size: 1em;
    }
    img {
      max-width: 95%;
      max-height: 95%;
    }
    video {
      max-width: 95%;
      max-height: 95%;
    }
    iframe {
      max-width: 95%;
      max-height: 95%;
    }
    li:before {
      content: '•';
      margin-right: 0.5em;
    }
    ol {

      list-style-type: decimal;
    }
    ol, ul {
      display: inline-block;
      li {
        @include highlight;
      }
      ol {
        display: block;
      }
      ul {
        display: block;
      }
    }
    dl {
      display: inline-block;
    }
    dt {
      font-weight: bold;
    }
    dd {
      margin-left: 40px;
    }
    blockquote {

      display: block;
      &::before {
        content: "";
        display: block;
        height: 1px;
        background: none !important;
      }
      width: 70%;
     // @include highlight(lighten(yellow, 40%));
     font-style: italic;
   }
   q {
    font-style: italic;
  }
  pre {
    display: block;
    position: relative;
    width: 90%;
    text-align: left;
    font-size: 0.55em;
    background-color: rgba(255, 255, 0, .4);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    code {
      display: block;
      padding: 5px;
      overflow: auto;
      max-height: 400px;
      word-wrap: normal;
    }
  }
  code {
    text-transform: none;
  }
  table {
    margin: auto;
    border-collapse: collapse;
    border-spacing: 0;
    td, th {
      text-align: left;
      padding: 0.5em;
      padding-bottom: 0.4em;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
        // border-bottom: 1px solid lighten(var(--color-fg), 60%);
      }
      th[align="center"] {
        text-align: center;
      }
      td[align="center"] {
        text-align: center;
      }
      th[align="right"] {
        text-align: right;
      }
      td[align="right"] {
        text-align: right;
      }
      tbody {
        tr {
          &:last-child {
            th {
              border-bottom: none;
            }
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
    sup {
      vertical-align: super;
      font-size: smaller;
    }
    sub {
      vertical-align: sub;
      font-size: smaller;
    }
    small {
      display: inline-block;
      font-size: 0.6em;
      vertical-align: top;
      * {
        vertical-align: top;
      }
    }
    a {
      transition: color .15s ease;
      &:hover {
        border: none;
        .r-frame {
          border-color: #00008B;
        }
      }
      .r-frame {
        transition: all .15s linear;
      }
    }
    .roll {
      span {
        &:after {
          background: #00003f;
        }
      }
    }
    .r-frame {
      border: 4px solid #000;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
    .controls {
      color: var(--color-fg);
      position: absolute !important;
    }
    .progress {
      background-color: white;
      @include tile(lines-down-a, black, 5px);
      color: var(--color-fg);
      height: 5px;
      width: 100%;
      @include radius(0);
    }
  }
  @media print {
  }
  picture {
    &.right {
      float: right;
    }
    &.mobile {
      @include fade(0, 20px, 0, $delay: var(--transition));
      background-color: #fff;
      @include shadow(box, 0 0 10px rgba(0, 0, 0, 0.10));
      @include transition;
      &.more-down:after, &.more-up:before, &.more-both:before, &.more-both:after {
       content: "";
       background-color: white;
       display: block;
       position: absolute;
       height: 10px;
     } 
     
     &.more-up {

      &:before {
        margin-top: -8px;
      }
    }
    &.more-both {
      @include radius(0);
      &:before {
        margin-top: -8px;
      }
    }
  }
}
.status {
  font-size: 1rem;
  @include highlight(rgba(0,0,0, .2));
  @include radius(20px);
  vertical-align: middle;
  text-transform: none;
}
}



.collab {
  a {
    img, svg {
      @include transition;
      height: var(--gap-3);
    }
    &:not(:hover) {
      filter: grayscale(1);
    }
  }
  svg {
    width: var(--gap-3);
    height: var(--gap-3);
    path {
      fill: purple;
    }
  }
  img {

   height: 35px;

   @include reset(all);
 }

}

.cover {

 h1 {
  font-size: 4em !important;

}
h2 {
  font-size: 2em !important;

}
h3 {
  font-size: 1.3em !important;
}


#blink-1, #blink-2, #blink-3, #blink-4 {
  @include flash($count: infinite, $duration: calc(var(--transition) * 3), $delay: 0);
}

#story1 {
  #survey-1 {
   @include fade(0, 20px, 0, $delay: var(--transition));
 }
 #survey-2 {
   @include fade(0, 20px, 0, $delay: (calc(var(--transition) * 2)));
 }
}
}

.horizontalteam {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  margin-top: 30px;


  div {
    span {
      font-size: 70% !important;
      line-height: 125%;
      display: block;

      padding: 5px 10px;
      margin-bottom: 10px;
      background-color: #fff;
      &.name {
        font-size: 80% !important;
        font-weight: bold;
      }
      &.links {

        font-size: 60% !important;
        a {
          margin-left: 5px;
        }
        &:before {
          content: "🔗"
        }


      }
    }
    
  }
}


.mesa-for-remote {
  h2, h1 {
   font-weight: bold;
 }
 section:first-of-type {
  h2 {
    font-weight: normal;
    font-size: 45px !important;
  }
}

}

.right {
  float: right;
  margin-left: 15px;
}


.left {
  float: left;
  margin-right: 15px;
}
$stagger: 300ms;
.stagger {

  &:nth-child(1) {
    @include fadeIn($delay: ($stagger * 1));
  }
  &:nth-child(2) {
    @include fadeIn($delay: ($stagger * 2));
  }
  &:nth-child(3) {
    @include fadeIn($delay: ($stagger * 3));
  }
  &:nth-child(4) {
    @include fadeIn($delay: ($stagger * 4));
  }
  &:nth-child(5) {
    @include fadeIn($delay: ($stagger * 5));
  }

  &:nth-child(6) {
    @include fadeIn($delay: ($stagger * 6));
  }
  &:nth-child(7) {
    @include fadeIn($delay: ($stagger * 7));
  }
  &:nth-child(8) {
    @include fadeIn($delay: ($stagger * 8));
  }
}


.mesa-1 {
  background-image: url(/images/mesa-for-remote/mesa-1.png);
  background-position: center center;
  background-size: cover;
  height: 600px;
  display: block;
}

.mesa-2 {
  background-image: url(/images/mesa-for-remote/mesa-2.png);
  background-position: center center;
  background-size: cover;
  height: 600px;
  display: block;
}

