.redux blockquote {
  width: calc(var(--pdf) * var(--pdf-ratio));
  height: var(--pdf);
  overflow: hidden;
  @include radius(3px);

  @include fadeInRight($delay: var(--delay), $duration: 1s);

  ul {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-points-x: repeat(calc(var(--pdf) * var(--pdf-ratio)));
    scroll-snap-type: mandatory;

  }

ul > li {

    scroll-snap-align: start;
    flex-shrink: 0;
    height: var(--pdf);
    width: calc(var(--pdf) * var(--pdf-ratio));
    &:not(:last-of-type) {
      border-right: solid 1px #ccc;
    }
    transform-origin: center center;
    transition: transform 0.5s;
    position: relative;
    display: flex;
  }
  img {
    object-fit: cover;
    position: absolute;
    height: var(--pdf);
    width: calc(var(--pdf) * var(--pdf-ratio));
  }
}
