@import "vendor/animatewithsass/animate.scss";
@import "vendor/bootstrap/bootstrap.scss";
@import "vendor/tile.scss";
@import "mixins";
@import "functions";

.box-content {
	display: grid;
	margin: 0 auto;
	a.logo svg {
		height: var(--logo-size);
		width: var(--logo-size);
	}
	@media screen and (min-width: $bp) {
		--logo-size: 100px;
		html.firefox body.home & a.logo svg {
			margin-top: 4px;
		}
		html:not(.firefox) body.home & a.logo svg {
			margin-top: -2px;
		}
		html.firefox body:not(.home) & a.logo svg {
			margin-top: 6px;
		}

		article.main footer.footer {
			display: none;
		}
		max-width: $bp;
		padding: var(--gap-2);
		grid-template-columns: var(--logo-size) 1fr;
		grid-column-gap: var(--gap-2);
		.fixed {
			height: 100vh;
			position: fixed;
			display: grid;
			grid-template-rows: var(--logo-size) 1fr;
			padding-bottom: var(--gap-6);
			footer.footer {
				align-self: flex-end;
				width: var(--logo-size);
			}
		}
	}
	@media screen and (max-width: $bp) {
		--logo-size: 60px;
		.logo {
			margin-left: -10px;
		}
		article.main {

			margin-top: 10px;
		}
		min-width: 100%;
		padding: 0;
		aside footer.footer {
			display: none;
		}
	}

}

.category-menu {
	
	@media screen and (max-width: $bp) {
		width: 100vw;
	}
	@media screen and (max-width: $bp - 60px) {
		width: 90vw;
	}
}


.redux {
	@extend .dark-mode;
	@media screen and (min-width: $bp) {
		.grid {
			display: grid;
			grid-gap: var(--gap-2);
			grid-template-columns: calc(var(--pdf) * var(--pdf-ratio)) 1fr;
		}
	}
	@media screen and (max-width: $bp) {
		.zine {
			margin: 0 auto;
		}
		.content {
			padding-top: var(--gap-2)
		}
	}
}








@import "layout";
@import "category";
@import "team";
@import "zine";
@import "cyborg";
@import "vendor/revealjs/reveal.scss";
@import "slide";
@import "bootstrap";
@import "wiley-table";