$bp: 700px;
:root {
    --font: Space Grotesk;
    --color-bg: white;
    --color-fg: black;
    --color-medium: #999;
    --color-dark: #666;
    --color-accent: purple;
    --color-accent-2: #edb6d3;
    --count: 100px;
    --logo-small: 36px;
    --pdf: 100vh;
    --nav: 150px;
    --container: 700px;
    --transition: 300ms;
    --lang: 50px;
    --pdf-ratio: 0.3863636364;
    --delay: 300ms;
    --gap: 15px;
    --CTA-size: 130px;
    --gap-2: calc(var(--gap) * 2);
    --gap-3: calc(var(--gap) * 3);
    --gap-4: calc(var(--gap) * 4);
    --gap-5: calc(var(--gap) * 5);
    --gap-6: calc(var(--gap) * 6);
    --gap-7: calc(var(--gap) * 7);
    --gap-8: calc(var(--gap) * 8);
    --gap-9: calc(var(--gap) * 9);
    --gap-10: calc(var(--gap) * 10);
    --gap-half: calc(var(--gap) / 2);
    --gap-quart: calc(var(--gap) / 4);
    --bg-image: url("../images/dot-bg.svg");
    &.dark-mode {
        --color-bg: #281831;
        --color-fg: white;
        --color-medium: white;
        --color-dark: #999;
        --color-accent: #edb6d3;
        --color-accent-2: purple;
        --bg-image: url("../images/dot-bg-dark-mode.svg");
        section.session .counter:before {
            background-color: rgba(white, 0.3);
        }
        .cover {
            display: none;
        }
    }
}

@mixin highlight($bg: var(--color-bg)) {
    display: inline-block;
    background: $bg;
    padding: var(--gap-half) var(--gap);
}

@mixin assorted-bgs {
    &:nth-of-type(1) {
        background: #2c3e50; /* fallback for old browsers */
        background: -webkit-linear-gradient(
            to right,
            #fd746c,
            #2c3e50
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            to right,
            #fd746c,
            #2c3e50
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    &:nth-of-type(2) {
        background: #aa4b6b; /* fallback for old browsers */
        background: -webkit-linear-gradient(
            to right,
            #3b8d99,
            #6b6b83,
            #aa4b6b
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            to right,
            #3b8d99,
            #6b6b83,
            #aa4b6b
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    &:nth-of-type(3) {
        background: #f12711; /* fallback for old browsers */
        background: -webkit-linear-gradient(
            to right,
            #f5af19,
            #f12711
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            to right,
            #f5af19,
            #f12711
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    &:nth-of-type(4) {
        background: #659999; /* fallback for old browsers */
        background: -webkit-linear-gradient(
            to right,
            #f4791f,
            #659999
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            to right,
            #f4791f,
            #659999
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
}

@mixin fade(
    $x: 0,
    $y: 0,
    $z: 0,
    $duration: $durationDefault,
    $function: $functionDefault,
    $delay: $delayDefault,
    $count: $countDefault,
    $fill: $fillDefault,
    $visibility: $visibilityDefault
) {
    @include animation-name(fade);
    @include duration($duration);
    @include function($function);
    @include delay($delay);
    @include count($count);
    @include visibility($visibility);
    @include fill-mode($fill);

    @at-root {
        @include keyframes(fade) {
            0% {
                opacity: 0;
                transform: translate3d($x, $y, $z);
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
}
