@include font-face(
    Space Grotesk,
    $path: "../fonts/",
    $file: SpaceGrotesk-Light,
    $weight: normal,
    $style: normal,
    $stretch: normal,
    $transform: normal
);

@include font-face(
    Space Grotesk,
    $path: "../fonts/",
    $file: SpaceGrotesk-Medium,
    $weight: bold,
    $style: normal,
    $stretch: normal,
    $transform: normal
);

@include font-face(
    Hasklig,
    $path: "../fonts/",
    $file: hasklig-light,
    $weight: normal,
    $style: normal,
    $stretch: normal,
    $transform: normal
);

footer.footer {
    body.home & nav {
        display: none;
    }

    --logo-small: 18px;
    aside & {
        nav {
            margin-bottom: var(--gap-2);
        }
        a {
            @include highlight;
            &:hover {
                @include highlight(var(--color-accent-2));
                color: var(--color-fg);
            }
        }
    }
    article.main & {
        display: inline;

        a {
            display: inline-block;
            &:not(:first-of-type) {
                margin-left: -6px;
            }
            background-color: var(--color-bg);
            padding: var(--gap-quart) var(--gap);
            &:hover {
                background-color: var(--color-accent-2);
                color: var(--color-fg);
            }
        }
    }

    a {
        text-decoration: none;
        font-size: 90%;
        &:not(.path) {
            text-transform: capitalize;
        }
        &.path {
            font-weight: bold;
            &:before {
                content: "/";
                background-image: url("../images/logo-small.svg");
                background-size: var(--logo-small) var(--logo-small);
                html.dark-mode & {
                    background-image: url("../images/logo-small-white.svg");
                }
                width: calc(var(--logo-small) + 7px);
                background-repeat: no-repeat;
                background-position: left 2px;
                display: inline-block;
                text-align: right;
            }
        }
    }
}

p {
    @include highlight;
    line-height: 160%;
}

li {
    background-color: var(--color-bg);
    border: solid 10px white;
    &:not(:first-of-type) {
        margin-top: 5px;
    }
}

.list {
    header.header {
        h1 {
            @include highlight(var(--color-fg));
        }
        h2 {
            text-transform: lowercase;
            font-weight: normal;
        }
    }
}

.logo {
    @include fadeInDown;
}
article.main,
.description {
    @include fadeInDown($delay: var(--delay));
}

.home {
    .description {
        padding-top: var(--gap-2);
        &.v5 {
            border-top: solid 3px var(--color-fg);
        }
        &.study {
            padding-bottom: var(--gap-2);
            border-bottom: solid 3px var(--color-fg);
            .category-description,
            .btn {
                margin-top: var(--gap);
            }
        }
    }

    header.header {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 15px;
    }
}

footer.footer {
    @include fadeIn($delay: calc(var(--delay) * 6));
}

section.category,
study blockuote,
section.group,
ul.list {
    &:nth-of-type(1) {
        @include fadeInDown($delay: calc(var(--delay) * 2));
    }
    &:nth-of-type(2) {
        @include fadeInDown($delay: calc(var(--delay) * 3));
    }
    &:nth-of-type(3) {
        @include fadeInDown($delay: calc(var(--delay) * 4));
    }
    &:nth-of-type(4) {
        @include fadeInDown($delay: calc(var(--delay) * 5));
    }
    &:nth-of-type(5) {
        @include fadeInDown($delay: calc(var(--delay) * 6));
    }
    &:nth-of-type(6) {
        @include fadeInDown($delay: calc(var(--delay) * 7));
    }
}

body {
    font-family: var(--font);
    padding: 20px;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    color: var(--color-fg);
    .background {
        background-image: var(--bg-image);
        background-color: var(--color-bg);
        top: 0;
        left: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        height: 100%;
        z-index: -100;
    }
}

a:not(.btn) {
    color: var(--color-fg);
    text-decoration: underline;
    @include transition;
    &:hover {
        color: var(--color-accent);
    }
}

.paths {
    @include highlight;
    font-size: 35px;
    font-weight: bold;
    &:before {
        content: "/";
        background-image: url("../images/logo-small.svg");
        html.dark-mode & {
            background-image: url("../images/logo-small-white.svg");
        }
        width: calc(var(--logo-small) + 20px);
        background-repeat: no-repeat;
        background-position: left 10px;
        display: inline-block;
        text-align: right;
    }
}

span.emoji {
    font-size: 150%;
    padding: 0 var(--gap-half);
}
code {
    background-color: var(--color-medium);
    padding: 0 var(--gap-half);
    margin: 0 var(--gap-quart);
    font-family: Hasklig;
    color: var(--color-bg);
}

html:not(.slide) {
    h1,
    h2,
    h3,
    h4 {
        @include highlight(var(--color-fg));
        color: var(--color-bg);
        text-transform: uppercase;
        font-weight: bold;
    }
}

body.home {
    header.header {
        h1 {
            @include highlight(var(--color-fg));
        }
    }
}

h1 {
    font-size: 45px;
}
h2 {
    font-size: var(--gap-2);
}
h3 {
    font-size: 25px;
}
header.header {
    margin-top: 8px;
    margin-bottom: var(--gap-2);
    h1,
    h2 {
        @include highlight(var(--color-fg));
        color: var(--color-bg);
        text-transform: uppercase;
    }

    .slogan {
        text-transform: capitalize;
        @media screen and (min-width: $bp) {
            width: 50px;
        }
        p {
            @include highlight(var(--color-fg));
            line-height: 150%;
            color: var(--color-bg);
            font-weight: normal;
            font-size: 22px;
        }
    }
}

a.btn {
    background-color: var(--color-fg);
    color: var(--color-bg);
    text-align: left;
    @include radius(0);
    font-weight: bold;
    @include transition;
    &:hover {
        background-color: var(--color-accent);
        color: var(--color-bg);
    }
    &:after {
        content: " +";
    }
    &.CTA {
        text-transform: uppercase;
        margin-top: -170px;
        text-align: center;
        line-height: 120%;
        background-color: #fafcb8;
        @include bounceIn($delay: var(--delay));
        display: flex;
        justify-content: center;
        flex-direction: column;
        float: right;
        @include radius(50%);
        .text {
            @include rotate(5);
            display: block;
            @include transition;
        }
        .sig {
            display: block;
            border-top: solid 1px var(--color-bg);
            margin-top: var(--gap-half);
            padding-top: var(--gap-half);
            font-size: 90%;
        }

        width: var(--CTA-size);
        height: var(--CTA-size);
        &:hover {
            background-color: var(--color-fg);
            .text {
                @include rotate(-5);
            }
        }
        &:after {
            content: "";
        }
    }
}

a.logo {
    svg {
        #background,
        #logo {
            fill: var(--color-fg);

            @include transition;
        }
        #border {
            fill: var(--color-bg);
        }
    }
    &:hover {
        #background,
        #logo {
            fill: var(--color-accent);
        }
    }
}

section.feature-list,
section.status,
.category-menu section {
    border-top: solid 3px var(--color-fg);
    &:not(:first-of-type) {
        margin-top: var(--gap-2);
    }
    &:last-of-type {
        border-bottom: solid 3px var(--color-fg);
        padding-bottom: var(--gap-2);
        @media screen and (max-width: $bp) {
            margin-bottom: var(--gap-2);
        }
    }
}
section.status {
    padding-top: var(--gap-2);
    h1,
    h2,
    h3,
    h4,
    h4 {
        @include highlight;
        color: var(--color-fg);
    }
    &:last-of-type {
        padding-bottom: var(--gap-2);
    }
}

.cover {
    position: fixed;
    border-bottom: dashed 3px var(--color-bg);
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: left center;
    top: 0;
    width: 100%;
    @media screen and (min-width: $bp) {
        height: 205px;
    }
    @media screen and (max-width: $bp) {
        height: 250px;
    }
    @include fadeInDown;
    .no-cover & {
        display: none;
    }
}

.category-menu section.status {
    &:nth-of-type(1) {
        @include fadeInDown($delay: calc(var(--delay) * 2));
    }
    &:nth-of-type(2) {
        @include fadeInDown($delay: calc(var(--delay) * 3));
    }
    &:nth-of-type(3) {
        @include fadeInDown($delay: calc(var(--delay) * 4));
    }
}

// .study {

// 	article {
// 		counter-reset: section;

// 	}
// 	&:not(.current) {
// 		.syllabus {
// 			.calendar, .room {
// 				display: none;
// 			}
// 		}
// 	}

// 		ul {
// 			padding: 0;
// 		}
// 		li {
// 		@include highlight;
// 		&:first-of-type {
// 				font-size: 30px;
// 		text-transform: uppercase;
// 		font-weight: bold;
// 		}
// 		}
// 		&:before {
// 			displaY: inline-block;
// 			width: var(--logo-size);
// 			height: var(--logo-size);
// 			background-color: rgba(black, .3);
// 			margin-top: -5px;
// 			counter-increment: section;                   /* Increment the value of section counter by 1 */
// 			content: counter(section);
// 			color: var(--color-fg);
// 				padding-top: 10px;
// 				font-size: 300%;

// 				@include radius(50%);

// 			text-align: center;
// 		}

// }

hr {
    background-color: black;
    height: 3px;
    margin: 40px 0;
}

.notes {
    border-top: solid 3px var(--color-medium);
    font-size: 90%;
    padding-top: var(--gap-2);
    margin-top: var(--gap-2);
    @include transition;
    &:not(:hover) {
        &,
        a {
            color: var(--color-dark);
        }
    }
}

span.badge {
    position: absolute;
    top: -5px;
    left: 20px;
    background-color: var(--color-accent);
    padding: 5px 10px 2px 10px;
    color: white;
    text-transform: uppercase;
    @include radius(0px);
    margin-right: -10px;
    margin-top: 5px;
}

.instructions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gap-2);
    border-top: solid 3px var(--color-fg);
    padding: var(--gap-2) 0;
    p {
        font-size: 90%;
    }
    svg {
        width: 130px;
        height: 130px;
        background-color: white;
        padding: var(--gap-half);

        margin-bottom: var(--gap);
    }
    .btn {
        margin-left: -10px;
        margin-top: var(--gap);
    }
}

.sig {
    bottom: var(--gap);
    left: var(--gap);
    @include opacity(40);
    z-index: 1000;
    &:hover {
        @include opacity(100);
    }
    @include transition;
    text-decoration: none !important;
    svg {
        height: var(--gap-2);
        width: var(--gap-2);
    }
    span {
        @include highlight(var(--color-fg));
        color: var(--color-bg);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 90%;
        margin-left: var(--gap-half);
    }
}

form.tinyletter {
    margin-top: var(--gap);
    padding-top: var(--gap);
    padding-right: var(--gap);
    background-color: var(--color-bg);
    display: grid;
    grid-template-columns: 1fr 150px;
    input {
        height: 50px;
        align-self: flex-start;
        padding: var(--gap) var(--gap-2) !important;
        &.input {
            height: 75px;
            margin-top: -13px;
        }
        in &.button {
            font-weight: bold;
            text-transform: uppercase;
            border: 0;
        }
    }
}

.diagram {
    margin-bottom: 40px;
    margin-left: -10px;

    @include transition;
    width: 520px;
    @media screen and (min-width: $bp) {
        padding: 10px;

        padding-bottom: 5px;
        background-color: white;
        @include shadow(box, 3px 3px 5px rgba(0, 0, 0, 0.1));
        &:hover {
            @include shadow(box, 3px 3px 5px rgba(0, 0, 0, 0.2));
        }
    }
    @media screen and (max-width: $bp) {
        width: 100%;
    }

    .original {
        text-decoration: none;
        background-color: #ccc;
        padding: 10px 15px;
        @include radius(20px);
        position: absolute;
        font-size: 70%;
        font-weight: bold;
        &:before {
            content: "View source";
        }
        @media screen and (max-width: $bp) {
            border: solid 1px black;
            @include shadow(box, 3px 3px 0px rgba(0, 0, 0, 0.1));
            &:hover {
                border: solid 1px purple;
                @include shadow(box, 2px 2px 0px rgba(0, 0, 0, 0.2));
            }
            &:before {
                content: "Click to view source on figma";
            }
        }
    }

    .scroll {
        overflow-y: hidden;
        flex-wrap: no-wrap;
        overflow-x: auto;

        padding-bottom: 15px;
        @media screen and (max-width: $bp) {
            img {
                display: none;
            }
        }
    }
}

p.pending:before {
    content: "Pending:";
    font-size: 70%;
    font-weight: bold;
    padding: 5px 10px;
    margin-right: 5px;
    background-color: #eee;
}

html.todo {
    ul {
        @include reset(list);
        padding: var(--gap);
        padding-bottom: 0;
        background-color: #ffffff;
        border: solid 1px #eee;
        li {
            padding: var(--gap-half) 0;
        }
    }
    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-right: var(--gap-half);
    }
}
table {
    width: 100%;
}

table td, table th {
background-color: white;
padding: var(--gap);
border-bottom: solid 1px #ccc;
}