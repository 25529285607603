section.category {
	margin-bottom: var(--gap-2);
	&:before {
		content: "";
		background-color: var(--color-fg);
		height: 3px;
		display: block;
		box-shadow: 
		-10px 0 0 var(--color-fg);
		margin-bottom: var(--gap-2);
	}
	&:last-of-type:after {
		content: "";
		background-color: var(--color-fg);
		height: 3px;
		display: block;
		box-shadow: 
		-10px 0 0 var(--color-fg);
		margin-top: var(--gap-2);

	}

	a.btn {
		@media screen and (min-width: $bp) {
			float: right;
			margin-top: -55px;
		}

	}
	.category-description {
		margin-top: var(--gap);
		p {
			@include highlight;
		}
	}
	.category-menu {
		@include transition;
		height: 0px;
		overflow-y: hidden;
		display: flex;
		flex-wrap: no-wrap;
		overflow-x: auto;
		max-width: 100%;
		.category-post {
			flex: 0 0 auto;
			margin-top: 20px;
			width: calc(var(--nav) * 2);
			&:hover {
				width: calc(var(--nav) * 2.3);
			}
		}
		a.btn { 
			white-space: nowrap;
			margin: auto var(--gap-2);

		}
	}
	
	&:hover .category-menu, html.touch & .category-menu {

		.category-post {
			@include fadeInLeft;

		}
	}
	html.touch & .category-menu {
		@include fadeInDown($delay: calc(var(--delay) * 4));
	}
	
}


.category-menu {
	@include transition;
	.category-post {
		padding: var(--gap);
		padding-bottom: 0;
		displaY: block;
		text-decoration: none;
		@include assorted-bgs;
		background-size: cover !important;
		background-position: center center !important;
		header {
			width: calc(var(--nav) * 2);
		}
		&:hover span.badge {
			background-color: black;
		}
		p {
			@include transition;
			text-transform: uppercase;
			font-weight: bold;

		}
		filter: grayscale(1);
		&:hover {
			filter: grayscale(0);
			p {
				@include highlight(var(--color-accent));
				color: var(--color-bg);

			}
		}

		.title {
			font-size: 150%;
			line-height: 100%;
			@include highlight;
		}
		.description {
			line-height: 120%;
			@include highlight;
		}

	}
}
